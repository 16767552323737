import React from 'react';
import { useDispatch } from 'react-redux';
import { goToNode, hasNodeLink } from '../../../organization/redux/actions';
import { CHARTS, MOOD_AND_CONFIDENCE } from '../../../powerBi/constants';
import { alertHistoryModal } from '../../../powerBi/redux/actions';
import { NavLink } from 'react-router-dom-v5-compat';
import { TdsTypography } from '@tds/react';
import { Box, Grid } from '@mui/material';
import TriangleUpIcon from '../../../../../lib/icons/TriangleUpIcon';
import TriangleDownIcon from '../../../../../lib/icons/TriangleDownIcon';
import TargetIcon from '../../../../../lib/icons/TargetIcon';

const NotificationType = {
  ORGANIZATION: 'Organization',
  ALERT: 'Alert',
};

export default function NotificationBoxItem({ notification, index }) {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (notification.type === NotificationType.ORGANIZATION) {
      return dispatch(goToNode(notification));
    }

    if (notification.type === NotificationType.ALERT) {
      let parent;

      if (notification.chartType === CHARTS.MOOD) {
        parent = MOOD_AND_CONFIDENCE;
      }
      return dispatch(
        alertHistoryModal({
          isOpen: true,
          chart: {
            parent: parent,
            name: notification.chartType,
          },
        })
      );
    }
  };

  const getPath = () => {
    if (notification.type === NotificationType.ALERT) {
      return '/moodandconfidence';
    }

    return '/organization';
  };

  const renderLink = () => {
    if (notification.type === NotificationType.ALERT) {
      return (
        <NavLink to={getPath()} onClick={handleClick}>
          <TdsTypography
            id={`btnViewNotifications${index}`}
            component="body-1"
            classes={['text-primary-500']}
          >
            {`View >>`}
          </TdsTypography>
        </NavLink>
      );
    }

    return (
      <NavLink
        to={getPath()}
        onClick={() => {
          dispatch(hasNodeLink(true));
          return dispatch(goToNode(notification));
        }}
      >
        <TdsTypography
          id={`btnViewNotifications${index}`}
          component="body-1"
          classes={['text-primary-500']}
        >
          {`View >>`}
        </TdsTypography>
      </NavLink>
    );
  };

  return (
    <Grid item sm={12} flexGrow sx={{ '&:hover': { background: '#f7f7f9' } }}>
      <Grid container alignItems="center">
        <Grid item sm={11}>
          <TdsTypography component="body-1" classes={['text-black']}>
            <span className="inline-block">
              {index}. {notification.info}{' '}
            </span>
            {notification.alertType === 'above' && (
              <span className="inline-block" style={{ marginLeft: '10px' }}>
                <TriangleUpIcon />
              </span>
            )}
            {notification.alertType === 'below' && (
              <span className="inline-block" style={{ marginLeft: '10px' }}>
                <TriangleDownIcon />
              </span>
            )}
            {notification.alertType === 'target' && (
              <span className="inline-block" style={{ marginLeft: '10px' }}>
                <TargetIcon />
              </span>
            )}
          </TdsTypography>
        </Grid>

        <Grid item sm={1} textAlign="right">
          <Box>{renderLink()}</Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
