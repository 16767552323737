import React, { useContext, useEffect, useState } from 'react';
import { Collapse, Grid, IconButton } from '@mui/material';
import Solution from './Solution';
import { OrganizationCardContext } from '../../../../context/OrganizationCardContextProvider';
import { StyledTogglePanel } from '../../../../../styled';
import BulbIcon from '../../../../../../../../../lib/icons/BulbIcon';
import PlusIcon from '../../../../../../../../../lib/icons/PlusIcon';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';
import { reverseAndUnshift, sortValidItems, splitList } from '../../utils';
import { TdsIcon } from '@tds/react';

export default function SolutionsPanel({ solutions, deliveryIndex, targetField }) {
  const context = useContext(OrganizationCardContext);
  const [isOpen, setIsOpen] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [openNewSolution, setOpenNewSolution] = useState(false);
  const [incompleteList, setIncompleteList] = useState([]);
  const [completeList, setCompleteList] = useState([]);

  useEffect(() => {
    setCanAdd(solutions[0].btnAdd.visible);
    setSolutionsItem();
  }, [solutions]);

  const setSolutionsItem = () => {
    if (!isOpen) {
      setIsOpen(context.shouldBeOpen(solutions, targetField.key));
    }

    const regExp = /^Solution\/Project [0-9]+$/;
    let { _incompleteItems, _completeItems } = splitList(solutions, regExp);

    _incompleteItems = _incompleteItems.map(solution => {
      return (
        <Solution
          key={solution.originalIndex}
          solution={solution}
          index={solution.originalIndex}
          deliveryIndex={deliveryIndex}
          color="#E6F8FC"
          targetField={targetField}
          openNewSolution={openNewSolution}
        />
      );
    });

    _completeItems = _completeItems.map(solution => {
      return (
        <Solution
          key={solution.originalIndex}
          solution={solution}
          index={solution.originalIndex}
          deliveryIndex={deliveryIndex}
          color="#E6F8FC"
          targetField={targetField}
          openNewSolution={openNewSolution}
        />
      );
    });

    _incompleteItems.map((_item, index) => {
      if (!_item.props.solution.name.value || regExp.test(_item.props.solution.name.value)) {
        _item.props.solution.name.value = `Solution/Project ${index + 1}`;
      }

      return _item;
    });

    setIncompleteList(_incompleteItems);
    setCompleteList(_completeItems);
  };

  const addItem = () => {
    setIsOpen(true);
    setOpenNewSolution(true);
    context.addNewSolution(deliveryIndex);
  };

  return (
    <>
      <StyledTogglePanel
        item
        sx={{
          flexGrow: 1,
          borderRadius: isOpen ? '5px 5px 0 0' : '5px',
          cursor: 'pointer',
        }}
      >
        <Grid container direction="row" alignItems="center">
          <Grid item mr={1}>
            <BulbIcon />
          </Grid>
          <Grid item>
            <EmwTypography component="caption-2" fontWeight="bold">
              Solutions/Projects
            </EmwTypography>
          </Grid>

          <Grid item ml="auto">
            <Grid container alignItems="center">
              {canAdd && (
                <IconButton
                  title="Add new"
                  sx={{ padding: 0 }}
                  disableRipple
                  onClick={addItem}
                  id="btnAddSolutions"
                >
                  <PlusIcon />
                </IconButton>
              )}
              <IconButton
                title={`${isOpen ? 'Collapse section' : 'Expand section'}`}
                disableRipple
                size="small"
                onClick={() => setIsOpen(!isOpen)}
                id={`${isOpen ? 'btnCollapseSolutions' : 'btnExpandSolutions'}`}
              >
                {isOpen ? (
                  <TdsIcon icon="keyboard_arrow_up" size="small" style={{ color: '#4A4D65' }} />
                ) : (
                  <TdsIcon icon="keyboard_arrow_down" size="small" style={{ color: '#4A4D65' }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </StyledTogglePanel>

      <Collapse className="toggle-collapse" in={isOpen}>
        {reverseAndUnshift(incompleteList, 'solution')}
        {sortValidItems(completeList, 'solution')}
      </Collapse>
    </>
  );
}
