import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StyledStaticEmbeddedPowerPi } from './styled';
import { accessTokenSelector } from '../redux/selectors';
import { MOOD_AND_CONFIDENCE, OPERATING_MODEL, TALENTS_AND_CULTURES } from '../constants';
import { biPropsSelector } from '../../header/redux/selectors';

window.report = {};

export default function PowerBi() {
  const biProps = useSelector(biPropsSelector);
  const accessToken = useSelector(accessTokenSelector);
  const [ssoUser, setSsoUser] = useState('');
  const [ssoPassword, setSsoPassword] = useState('');
  const [pbiUrls, setPbiUrls] = useState({});
  const [tdpReports, setTdpReports] = useState();

  useEffect(() => {
    if (biProps.list.hasOwnProperty('data')) {
      setSsoUser(biProps.list.data.hzaUser);
      setSsoPassword(biProps.list.data.hzaPassword);
      const pbiUrlsObj = {};
      pbiUrlsObj['/skills'] = biProps.list.data.skillsFocusC2Url;
      pbiUrlsObj['/engineeringcompetitivness'] = biProps.list.data.engineeringCompetitivnessC3Url;
      pbiUrlsObj['/jobfamily'] = biProps.list.data.jobFamilyFocusUrl;
      pbiUrlsObj['/moodandconfidence'] = biProps.list.data.moodAndConfidenceUrl;
      pbiUrlsObj['/operatingmodel'] = biProps.list.data.wfbC2URL;
      setPbiUrls(pbiUrlsObj);

      setTdpReports([
        {
          name: MOOD_AND_CONFIDENCE,
          id: `${MOOD_AND_CONFIDENCE}-report`,
          embedUrl: biProps.list.data.moodAndConfidenceC2Url,
          visible: true,
        },
        {
          name: OPERATING_MODEL,
          id: `${OPERATING_MODEL}-report`,
          embedUrl: biProps.list.data.wfbC2URL,
          visible: true,
        },
        {
          name: TALENTS_AND_CULTURES,
          id: `${TALENTS_AND_CULTURES}-report`,
          embedUrl: biProps.list.data.skillsFocusC2Url,
          visible: true,
        },
      ]);
    }
  }, [biProps]);

  if (tdpReports) {
    return (
      <>
        {tdpReports.map(report => (
          <div
            id={report.id}
            key={report.id}
            data-name={report.name}
            className="bi-report-container h-full hidden"
          >
            <StyledStaticEmbeddedPowerPi
              embedUrl={report.embedUrl}
              ssoUser={ssoUser}
              ssoPassword={ssoPassword}
              pbiUrls={pbiUrls}
              accessToken={accessToken}
              reportName={report.name}
            />
          </div>
        ))}
      </>
    );
  }
}
