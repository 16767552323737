const calculateAutocompleteSugestionForEmployee = value => {
  const info = [value.firstName + ' ' + value.lastName + ' ' + value.tgi];
  value.roles.forEach(element => {
    // info.push(<br />)
    info.push(element);
  });

  return info;
};

export { calculateAutocompleteSugestionForEmployee };
