export const API_ENDPOINT = '/api/v1/emw';
export const SECURITY_API_ENDPOINT = '/api/v1/emw/security';
export const WORKER_API_ENDPOINT = 'employee/filteredEmployees';
export const SEARCH_ORGANIZATION = 'organization/searchOrganization';
export const COUNTRY_API_ENDPOINT = 'basicdata/getCountries';

export const ENVS = {
  DEV: 'dev',
  CLOUD: 'cloud',
  CLOUD_DEV: 'cloud_dev',
  CLOUD_TEST: 'cloud_test',
  CLOUD_INT: 'cloud_int',
  DIGITAL: 'digital',
  DIGITAL_DEV: 'digital_dev',
  DIGITAL_TEST: 'digital_test',
  DIGITAL_INT: 'digital_int',
  DIGITAL_PPD: 'digital_ppd',
};
