import React, { useContext, useEffect } from 'react';
import { EmwButton } from '../../../../../../../lib/common';
import { TdsIcon } from '@tds/react';
import { ORGANIZATION_CARD_MODAL_TYPE } from '../../../../constants';
import OrganizationCardModal from '../OrganizationCardModal/OrganizationCardModal';
import { OrganizationCardContext } from '../../context/OrganizationCardContextProvider';
import { useDispatch } from 'react-redux';
import { getDisciplineNames } from '../../../../redux/actions';
import TourElement from '../../../../../../components/Tour/TourElement/TourElement';
import { feedbackInsideModal } from '../../../../../feedback/redux/actions';

export default function AddOrganizationBtn({
  btn,
  isOpen,
  setIsOpen,
  targetField,
  setTargetField,
  parentCountryId,
}) {
  const context = useContext(OrganizationCardContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isOpen) {
      setTargetField({ section: '', key: '' });
    }
  }, [isOpen]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = async () => {
    context.setType(ORGANIZATION_CARD_MODAL_TYPE.CREATE);
    context.setData(null);
    setIsOpen(true);
    dispatch(getDisciplineNames());
    dispatch(feedbackInsideModal(true));
  };

  return (
    <>
      <TourElement id="btnAddNewOrganization">
        <EmwButton
          variant="ghost"
          size="small"
          disabled={!btn.enabled}
          onClick={handleClick}
          id={`btnAddNew${context.getOrgType()}`}
        >
          <TdsIcon
            icon="add_circle_outline"
            size="small"
            title={`Add new ${context.getOrgType() === 'BU' ? 'GBU' : context.getOrgType()}`}
          />
        </EmwButton>
      </TourElement>
      {isOpen && context.type === ORGANIZATION_CARD_MODAL_TYPE.CREATE && (
        <OrganizationCardModal
          parentCountryId={parentCountryId}
          isOpen={context.formModalOpen}
          toggle={toggle}
          targetField={targetField}
        />
      )}
    </>
  );
}
