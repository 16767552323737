import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { setReportDefaultPage } from '../../hooks/useReportRendering';
import { userActivity } from '../../../userActivity/redux/actions';
import { v4 as uuidv4 } from 'uuid';
import { USER_ACTIVITY_SERVICES } from '../../../userActivity/constants';
import { QtmTab, QtmTabs } from '@qtm/react';

const StyledTabsContainer = styled(Box)(() => ({
  position: 'relative',
  height: 'inherit',
  'tds-tabs': {
    height: 'inherit',
  },
}));

const TABS = {
  COMPETITIVENESS: {
    name: 'Competitiveness',
    slug: '',
    disabled: true,
    id: 'competitiveness',
  },
  OPERATING_MODEL: {
    name: 'Operating Model',
    slug: 'operating-model',
    disabled: false,
    id: 'competitiveness-operating-model',
  },
  CRAFTMANSHIP: {
    name: 'Craftnamship',
    slug: 'craftnamship',
    disabled: true,
    id: 'competitiveness-craftmanship',
  },
  DIGITAL_TRANSFORMATION: {
    name: 'Digital Transformation',
    slug: 'digital-transformation',
    disabled: true,
    id: 'competitiveness-digital-transformation',
  },
  TALENTS_AND_CULTURE: {
    name: 'Talents & Culture',
    slug: 'talents-and-culture',
    disabled: false,
    id: 'competitiveness-talents-and-culture',
  },
};

export default function EngineeringCompetitivenessMenu({ className }) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [selectedReport, setSelectedReport] = useState(params.report);

  useEffect(() => {
    if (/\/engineeringcompetitivness\/(.*)/.test(location.pathname)) {
      return setShow(true);
    }

    return setShow(false);
  }, [location]);

  useEffect(() => {
    setSelectedReport(params.report);
  }, [params]);

  const handleClick = async reportPage => {
    await setReportDefaultPage();
    const userActivityService = reportPage.toUpperCase().replace(/-/g, '_');
    dispatch(
      userActivity({
        uniqueId: uuidv4(),
        service: USER_ACTIVITY_SERVICES[userActivityService],
        details: 'VIEW',
        uri: '',
      })
    );
    navigate({
      pathname: `/engineeringcompetitivness/${reportPage}`,
    });
  };

  const isSelected = reportPage => {
    if (selectedReport === reportPage) {
      return 'qtm-active';
    }

    return '';
  };

  return (
    <StyledTabsContainer className={className}>
      {show && (
        <>
          <QtmTabs scrollable fullHeight scrollHorizontally>
            {Object.keys(TABS).map(key => (
              <QtmTab
                key={key}
                classes={isSelected(TABS[key].slug)}
                disabled={TABS[key].disabled}
                onClickedTabEvent={() => handleClick(TABS[key].slug)}
              >
                {TABS[key].name}
              </QtmTab>
            ))}
          </QtmTabs>
        </>
      )}
    </StyledTabsContainer>
  );
}
