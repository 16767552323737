import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledAlertOption, StyledHistoryModal } from './styled';
import {
  commentHistoryModalSelector,
  commentsRolesSelector,
  commentsSelector,
} from '../../redux/selectors';
import { Box, DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@mui/material';
import EmwTypography from '../../../../components/EmwTypography/EmwTypography';
import EmwExportBtn from '../../../../components/EmwExportBtn/EmwExportBtn';
import { TdsDropdownSelect, TdsIcon } from '@tds/react';
import { commentsHistoryModal, updateComments } from '../../redux/actions';
import { EmwButton } from '../../../../../lib/common';
import EmwDatePicker from '../../../../../lib/commonv2/EmwDatePicker';
import CommentsHistoryItem from './CommentsHistoryItem';
import { cloneDeep } from 'lodash';
import usePowerBi from '../../hooks/usePowerBi';
import { CHARTS, COMMENT_TYPES } from '../../constants';
import CreateCommentModal from '../CreateCommentModal/CreateCommentModal';
import { userSelector } from '../../../header/redux/selectors';

const SORT = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const visibilityOptions = [
  { label: 'My own comments', value: 'ownComments' },
  { label: 'Private', value: 'private' },
  { label: 'Public (IN THALES)', value: 'inThales' },
  { label: 'Public in my org - ALL', value: 'inMyOrgAll' },
  { label: 'Public in my org - LOCAL', value: 'inMyOrgLocal' },
];

export default function CommentsHistoryModal({ selectedChart }) {
  const dispatch = useDispatch();
  const historyModal = useSelector(commentHistoryModalSelector);
  const commentsData = useSelector(commentsSelector);
  const commentsRoles = useSelector(commentsRolesSelector);
  const user = useSelector(userSelector);
  const powerBi = usePowerBi();
  const [comments, setComments] = useState([]);
  const [chartName, setChartName] = useState('');
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [typeOptions, setTypeOptions] = useState([]);
  const [isCreateCommentOpen, setIsCreateCommentOpen] = useState(false);
  const createdBy = `${user.list.data.firstName} ${user.list.data.lastName}`;

  const [orgName, setOrgName] = useState('Show All');
  const [type, setType] = useState('');
  const [visibility, setVisibility] = useState('');
  const [order, setOrder] = useState(SORT.DESC);
  const [filters, setFilters] = useState({
    orgName: '',
    visibility: '',
    type: '',
    order: '',
    dateFrom: '',
    dateTo: '',
  });

  const orgs = useMemo(() => {
    const options = [];
    const values = commentsData[selectedChart.parent][selectedChart.key].values;

    values.forEach(value => {
      const optionExists = options.find(option => {
        const label = value.orgName || 'Group';
        return option.label === label;
      });

      if (!optionExists) {
        const orgName = value.orgName || 'Group';
        options.push({
          label: orgName,
          value: orgName,
        });
      }
    });

    return options;
  }, []);

  useEffect(() => {
    const _typeOptions = [];

    if (commentsRoles.canAddComment) {
      _typeOptions.push({ label: 'Comments', value: COMMENT_TYPES.COMMENT });
    }

    if (commentsRoles.canAddRootCause) {
      _typeOptions.push({ label: 'Root Cause', value: COMMENT_TYPES.ROOT_CAUSE });
    }

    if (commentsRoles.canAddActionPlan) {
      _typeOptions.push({ label: 'Action Plan', value: COMMENT_TYPES.ACTION_PLAN });
    }

    setTypeOptions(_typeOptions);
  }, [commentsRoles]);

  useEffect(() => {
    const { parent, name } = historyModal.chart;
    setChartName(commentsData[parent][name].title.toUpperCase());

    applyFilters();
  }, [commentsData]);

  useEffect(() => {
    applyFilters();
  }, [filters]);

  const getFullList = () => {
    return commentsData[historyModal.chart.parent][historyModal.chart.name].values.map(comment => {
      if (comment.orgName === null) {
        comment.orgName = 'Group';
      }

      return comment;
    });
  };

  const applyFilters = () => {
    const unfilteredComments = getFullList();

    const filterKeys = ['orgName', 'type'].filter(key => filters[key]);
    const filterValues = filterKeys.map(key => filters[key]);
    let filteredComments = unfilteredComments.filter(comment => {
      return filterKeys.every(key => {
        return filterValues.includes(comment[key]);
      });
    });

    if (filters.visibility === 'private') {
      filteredComments = filteredComments.filter(comment => {
        return comment.public === false;
      });
    } else if (['inThales', 'inMyOrgAll', 'inMyOrgLocal'].includes(filters.visibility)) {
      filteredComments = filteredComments.filter(comment => {
        return comment.publicIn === filters.visibility;
      });
    } else if (filters.visibility === 'ownComments') {
      filteredComments = filteredComments.filter(comment => {
        return comment.createdBy === createdBy;
      });
    }

    if (filters.order === SORT.ASC) {
      filteredComments.sort((first, second) => {
        return new Date(first.updatedDate) - new Date(second.updatedDate);
      });
    }

    if (filters.order === SORT.DESC) {
      filteredComments.sort((first, second) => {
        return new Date(second.updatedDate) - new Date(first.updatedDate);
      });
    }

    if (filters.dateFrom) {
      filteredComments = filteredComments.filter(comment => {
        return new Date(comment.updatedDate).getTime() >= filters.dateFrom.getTime();
      });
    }

    if (filters.dateTo) {
      filteredComments = filteredComments.filter(comment => {
        const createdAt = new Date(new Date(comment.updatedDate).toLocaleDateString()).getTime();
        return createdAt <= filters.dateTo.getTime();
      });
    }

    setComments(filteredComments);
  };

  const handleChange = (event, filter) => {
    const { selectedOption } = event.detail;

    switch (filter) {
      case 'visibility':
        setVisibility(selectedOption);
        setFilters(currentFilters => {
          return { ...currentFilters, visibility: selectedOption };
        });
        break;
      case 'orgName':
        setOrgName(selectedOption);
        setFilters(currentFilters => {
          return { ...currentFilters, orgName: selectedOption };
        });
        break;
      case 'type':
        setType(selectedOption);
        setFilters(currentFilters => {
          return { ...currentFilters, type: selectedOption };
        });
        break;
      case 'order':
        setOrder(currentOrder => {
          const orderVal = currentOrder === SORT.DESC ? SORT.ASC : SORT.DESC;

          setFilters(currentFilters => {
            return { ...currentFilters, order: orderVal };
          });

          return orderVal;
        });
    }
  };

  const handleDateFromChange = date => {
    setDateFrom(date);
    setFilters(currentFilters => {
      return { ...currentFilters, dateFrom: date };
    });
  };

  const handleDateToChange = date => {
    setDateTo(date);
    setFilters(currentFilters => {
      return { ...currentFilters, dateTo: date };
    });
  };

  const syncState = () => {
    const comments = cloneDeep(commentsData);

    powerBi.fetchComments(
      {
        chartType: Object.keys(CHARTS).map(key => CHARTS[key]),
        generalFilters: powerBi.getGeneralFilters(),
        timeframeFilters: powerBi.getTimeframeFilters(),
      },
      comments
    );
  };

  return (
    <StyledHistoryModal open={historyModal.isOpen} fullWidth disableEscapeKeyDown>
      <DialogTitle className="dialog-title">
        <Grid
          container
          justifyContent="space-between"
          flexWrap="nowrap"
          className="items-center border-b-2 border-primary-500 pb-xs"
        >
          <Grid item className="flex items-center">
            <EmwTypography classes="text-primary-500" fontSize={6} fontWeight="bold">
              Comments History - {chartName}
            </EmwTypography>
            <EmwExportBtn root="chart" action="exportComments" />
          </Grid>
          <Grid item className="flex-end">
            <label className="flex type-selector items-center border-bluegrey-200 pr-xs">
              <EmwTypography fontSize={9} classes="mr-xxs">
                ORGANIZATION:
              </EmwTypography>
              <TdsDropdownSelect
                name="orgName"
                classes="orgName"
                placeholder="Show all"
                value={orgName}
                onSelectedOption={event => handleChange(event, 'orgName')}
              >
                <div>
                  <StyledAlertOption value="">Show all</StyledAlertOption>
                  {orgs.map(option => {
                    return (
                      <StyledAlertOption value={option.value} key={option.value}>
                        {option.label}
                      </StyledAlertOption>
                    );
                  })}
                </div>
              </TdsDropdownSelect>
            </label>
          </Grid>
        </Grid>

        <Grid
          container
          className="filters mt-s items-center"
          flexWrap="nowrap"
          sx={{ overflow: 'hidden' }}
        >
          <Grid item>
            <label className="flex visibility-selector items-center border-bluegrey-200 pr-xs mr-xs">
              <EmwTypography fontSize={9} classes="mr-xxs">
                VIEW:
              </EmwTypography>
              <TdsDropdownSelect
                placeholder="View all"
                value={visibility}
                onSelectedOption={event => handleChange(event, 'visibility')}
              >
                <div>
                  <StyledAlertOption value="all">Show all</StyledAlertOption>
                  {visibilityOptions.map(typeOption => {
                    return (
                      <StyledAlertOption key={typeOption.value} value={typeOption.value}>
                        {typeOption.label}
                      </StyledAlertOption>
                    );
                  })}
                </div>
              </TdsDropdownSelect>
            </label>
          </Grid>
          <Grid item>
            <label className="flex type-selector items-center border-bluegrey-200 pr-xs">
              <EmwTypography fontSize={9} classes="mr-xxs">
                TYPE:
              </EmwTypography>
              <div>
                <TdsDropdownSelect
                  placeholder="Select Type"
                  value={type}
                  onSelectedOption={event => handleChange(event, 'type')}
                >
                  <div>
                    <StyledAlertOption value="">Show all</StyledAlertOption>
                    {typeOptions.map(typeOption => {
                      return (
                        <StyledAlertOption key={typeOption.value} value={typeOption.value}>
                          {typeOption.label}
                        </StyledAlertOption>
                      );
                    })}
                  </div>
                </TdsDropdownSelect>
              </div>
            </label>
          </Grid>

          <Grid item className="d-flex sort-filter px-xs mr-xs flex items-center justify-between">
            <EmwTypography fontSize={9}>Sort</EmwTypography>
            {order === SORT.DESC && (
              <EmwButton
                size="small"
                variant="ghost"
                onClick={event => handleChange(event, 'order')}
              >
                <TdsIcon icon="arrow_downward" size="medium" />
              </EmwButton>
            )}
            {order === SORT.ASC && (
              <EmwButton
                size="small"
                variant="ghost"
                onClick={event => handleChange(event, 'order')}
              >
                <TdsIcon icon="arrow_upward" size="medium" />
              </EmwButton>
            )}
          </Grid>

          <Grid item className="flex m-auto">
            <label className="flex items-center pr-xs">
              <EmwTypography fontSize={9} classes="mr-xxs">
                FROM:
              </EmwTypography>
              <EmwDatePicker
                inputId="start-date"
                disablePast={false}
                handleChange={handleDateFromChange}
                maxDate={dateTo}
              />
            </label>
            <label className="flex items-center pr-xs">
              <EmwTypography fontSize={9} classes="mr-xxs">
                TO:
              </EmwTypography>
              <EmwDatePicker
                inputId="end-date"
                handleChange={handleDateToChange}
                minDate={dateFrom}
              />
            </label>
          </Grid>
        </Grid>
      </DialogTitle>

      <Box sx={{ padding: '0 15px 10px 15px' }}>
        <Divider sx={{ height: '2px' }} />
      </Box>

      <DialogContent sx={{ minHeight: '300px' }}>
        {comments.map(comment => {
          return (
            <CommentsHistoryItem
              key={comment.id}
              comment={comment}
              syncState={syncState}
              title={chartName}
              selectedChart={selectedChart}
            />
          );
        })}
      </DialogContent>

      <Box sx={{ padding: '0 15px 10px 15px' }}>
        <Divider sx={{ height: '2px' }} />
      </Box>

      <DialogActions>
        <EmwButton
          className="mb-xs"
          variant="outline"
          color="primary"
          classes="mr-xs"
          size="small"
          onClick={() => dispatch(commentsHistoryModal({ isOpen: false, chart: null }))}
        >
          Close
        </EmwButton>
        <EmwButton
          variant="filled"
          color="primary"
          size="small"
          onClick={() => setIsCreateCommentOpen(true)}
        >
          Create Comment
        </EmwButton>
      </DialogActions>
      {isCreateCommentOpen && typeOptions.length > 0 && (
        <CreateCommentModal
          isOpen={isCreateCommentOpen}
          setIsOpen={setIsCreateCommentOpen}
          selectedChart={selectedChart}
          typeOptions={typeOptions}
        />
      )}
    </StyledHistoryModal>
  );
}
