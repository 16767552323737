import { useEffect, useState } from 'react';
import { ENVS } from './constants';

export default function useEnv() {
  const [env, setEnv] = useState('');
  const THALES_DIGITAL = 'thalesdigital.io';
  const THALES_CLOUD = 'cloud.thales';

  useEffect(() => {
    setEnv(env => {
      const host = window.location.host;
      if (host.includes('localhost')) {
        return ENVS.DEV;
      }

      if (host.includes(THALES_DIGITAL) && host.includes('dev')) {
        return ENVS.DIGITAL_DEV;
      }

      if (host.includes(THALES_DIGITAL) && host.includes('test')) {
        return ENVS.DIGITAL_TEST;
      }

      if (host.includes(THALES_DIGITAL) && host.includes('int.emw')) {
        return ENVS.DIGITAL_INT;
      }

      if (host.includes(THALES_DIGITAL) && host.includes('ppd')) {
        return ENVS.DIGITAL_PPD;
      }

      if (host.includes(THALES_CLOUD) && host.includes('dev')) {
        return ENVS.CLOUD_DEV;
      }

      if (host.includes(THALES_CLOUD) && host.includes('test')) {
        return ENVS.CLOUD_TEST;
      }

      if (host.includes(THALES_CLOUD) && host.includes('int-emw')) {
        return ENVS.CLOUD_INT;
      }

      if (host.includes('thalesdigital.io')) {
        return ENVS.DIGITAL;
      }

      if (host.includes('cloud.thales')) {
        return ENVS.CLOUD;
      }
    });
  }, []);

  return env;
}
