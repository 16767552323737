import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment/moment';
import { TdsIcon, TdsTextInput } from '@tds/react';
import { EmwAutocomplete, EmwButton } from '../../../../../../lib/common';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../constants';
import EmwTypography from '../../../../../components/EmwTypography/EmwTypography';
import { calculateAutocompleteSugestionForEmployee } from '../../../utils';
import { WORKER_API_ENDPOINT } from '../../../../../../constants';
import EmwDatePicker from '../../../../../../lib/commonv2/EmwDatePicker';
import TourElement from '../../../../../components/Tour/TourElement/TourElement';

const StyledButton = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  'tds-button': {
    width: '100%',
  },
  button: {
    width: '100%',
  },
}));

const Root = styled(Grid)(({ theme }) => ({
  padding: '8px',
  backgroundColor: '#F7F7F9',
  borderRadius: '10px',
  marginTop: '8px',
  '.start-date': {
    marginRight: '9px',
  },
  '.end-date': {
    marginRight: '11px',
    marginLeft: '12px',
  },
  '.guest-name': {
    textDecoration: 'underline',
  },
}));

const StyledTogglePanelItem = styled(Grid)(({ theme }) => ({
  fontSize: '10px',
  marginTop: '10px',
  borderRadius: '10px',
  padding: '5px',
  fontWeight: 'bold',
  backgroundColor: '#F7F7F9',
  '.name': {
    marginLeft: '5px',
  },
}));

export default function GuestActionFormItem({
  label,
  handleChange,
  index,
  values,
  canEdit,
  isNew,
  removeItem,
  id,
}) {
  const [isEdit, setIsEdit] = useState(isNew);
  const [inputId] = useState(`${label}-${index}`);
  const [itemValues, setItemValues] = useState({ ...values });

  useEffect(() => {
    setItemValues(values);
  }, []);

  const calculateLabelValue = selectedOption => {
    setItemValues({
      ...itemValues,
      id: selectedOption.value,
      name: {
        ...itemValues.name,
        value: `${selectedOption.object.firstName} ${selectedOption.object.lastName}`,
      },
    });

    return selectedOption && selectedOption.object
      ? selectedOption.object.firstName + ' ' + selectedOption.object.lastName
      : '';
  };

  const handleStartDateChange = date => {
    setItemValues({
      ...itemValues,
      startDate: {
        ...itemValues.startDate,
        value: moment(date).format(DATE_TIME_FORMAT),
      },
    });
  };

  const handleEndDateChange = date => {
    setItemValues({
      ...itemValues,
      endDate: {
        ...itemValues.endDate,
        value: moment(date).format(DATE_TIME_FORMAT),
      },
    });
  };

  const handleCommentChange = event => {
    const { value } = event.currentTarget;

    setItemValues({
      ...itemValues,
      comment: {
        ...itemValues.comment,
        value,
      },
    });
  };

  const handleClick = () => {
    handleChange(index, itemValues);
    setIsEdit(false);
  };

  const handleCancel = () => {
    setIsEdit(false);
    setItemValues(values);
    if (isNew) {
      removeItem(index);
    }
  };

  const renderActions = () => {
    return (
      <Grid item>
        <Grid container alignItems="center" justifyContent="end">
          <Divider orientation="vertical" flexItem sx={{ margin: '0 10px 0' }} />
          {canEdit && values.btnEdit.visible && (
            <EmwButton
              classes="edit-button"
              variant="filled"
              size="small"
              onClick={() => setIsEdit(!isEdit)}
              disableRipple
              title="Edit"
              id={`btnEdit${id}-${index}`}
            >
              <TdsIcon icon="edit" variant="outlined" size="small" />
            </EmwButton>
          )}
        </Grid>
      </Grid>
    );
  };
  const renderDates = () => {
    if (itemValues.hasOwnProperty('startDate') && itemValues.hasOwnProperty('endDate')) {
      return (
        <>
          {itemValues.startDate.visible && (
            <Box>
              <span style={{ marginRight: '5px' }}>From:</span>{' '}
              {Boolean(itemValues.startDate.value) &&
                moment(itemValues.startDate.value).format(DATE_FORMAT)}
            </Box>
          )}
          {itemValues.endDate.visible && (
            <Box>
              <span style={{ marginRight: '5px' }}>To:</span>{' '}
              {Boolean(itemValues.endDate.value) &&
                moment(itemValues.endDate.value).format(DATE_FORMAT)}
            </Box>
          )}
        </>
      );
    }

    return null;
  };
  const renderComments = () => {
    if (itemValues.hasOwnProperty('comment')) {
      return (
        <EmwTypography classes="text-black-100 ml-m" fontSize="9" fontWeight="800">
          {itemValues.comment.value}
        </EmwTypography>
      );
    }
    return null;
  };

  if (!isEdit) {
    return (
      <TourElement id={`first-item${id}`}>
        <StyledTogglePanelItem item>
          <Grid container alignItems="center">
            <Grid item>
              <EmwTypography classes="text-bluegrey-800 name" fontSize="9" fontWeight="800">
                {itemValues.name.value}
              </EmwTypography>
            </Grid>
            <Grid item>{renderComments()}</Grid>
            <Grid item ml="auto">
              <Grid container direction="column" alignItems="end" sx={{ lineHeight: 'normal' }}>
                {renderDates()}
              </Grid>
            </Grid>
            {renderActions()}
          </Grid>
        </StyledTogglePanelItem>
      </TourElement>
    );
  }

  return (
    <Root container className={`form-edit-container tour-${id}`}>
      <Grid item sm={12} md={10}>
        <Grid container>
          <Grid container sx={{ alignItems: 'center', marginBottom: '8px' }}>
            <Grid item>
              <EmwTypography
                classes="text-bluegrey-800 mr-xs guest-name "
                fontSize="9"
                fontWeight="800"
              >
                {label}
              </EmwTypography>
            </Grid>
            <Grid item sm={6}>
              <EmwAutocomplete
                inputId={inputId}
                urlToFetch={WORKER_API_ENDPOINT}
                variant={'outlined'}
                size={'small'}
                disabled={!itemValues.btnEdit.enabled}
                placeHolder={itemValues.name.value}
                calculateAutocompleteSugestion={calculateAutocompleteSugestionForEmployee}
                calculateAutocompleteLabel={calculateLabelValue}
              />{' '}
              {/* TODO: move this function into a generic hook */}
            </Grid>
            <Grid item>
              <TdsTextInput
                id={`comment-${inputId}`}
                classes="ml-s mr-s comment"
                placeholder="Comment"
                size="small"
                value={itemValues.comment.value}
                onValueChanged={handleCommentChange}
              />
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item>
              <EmwTypography fontSize="9" classes={'start-date'}>
                From:
              </EmwTypography>
            </Grid>
            <Grid item>
              <EmwDatePicker
                inputId={`${label}-${index}-start-date`}
                date={
                  itemValues.hasOwnProperty('startDate')
                    ? itemValues.startDate.value
                    : itemValues.date.value
                }
                disabled={!itemValues.btnEdit.enabled}
                handleChange={handleStartDateChange}
              />
            </Grid>
            <Grid item>
              <EmwTypography fontSize="9" classes={'end-date'}>
                To:
              </EmwTypography>
            </Grid>
            <Grid item>
              <EmwDatePicker
                inputId={`${label}-${index}-end-date`}
                date={itemValues.endDate.value}
                disabled={!itemValues.btnEdit.enabled}
                handleChange={handleEndDateChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={12} md={2}>
        <Grid container direction={'column'}>
          <StyledButton item sx={{ marginBottom: '2px' }}>
            <EmwButton
              variant="outline"
              size="small"
              id={`btnFormItemCancel-${id}-${index ? index : '0'}`}
              onClick={handleCancel}
            >
              Cancel
            </EmwButton>
          </StyledButton>

          <StyledButton item>
            <EmwButton
              variant="filled"
              size="small"
              id={`btnFormItemUpdate-${label}-${index ? index : '0'}`}
              sx={{ button: { width: '100%' } }}
              onClick={handleClick}
              disabled={!itemValues.name.value ? true : false}
            >
              Update
            </EmwButton>
          </StyledButton>
        </Grid>
      </Grid>
    </Root>
  );
}
