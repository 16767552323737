import React, { useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@mui/material';
import { EmwButton } from '../../../../../lib/common';
import EmwTypography from '../../../../components/EmwTypography/EmwTypography';
import { TdsDropdownSelect, TdsIcon } from '@tds/react';
import EmwDatePicker from '../../../../../lib/commonv2/EmwDatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { alertHistoryModalSelector } from '../../redux/selectors';
import { alertHistoryModal } from '../../redux/actions';
import useApi from '../../../../hooks/useApi';
import { CHARTS } from '../../constants';
import { StyledHistoryModal } from './styled';
import HistoryItem from './components/HistoryItem/HistoryItem';
import ctx from 'classnames';
import AlertOption from './components/AlertOption/AlertOption';
import { cloneDeep } from 'lodash';
import noAlertsAvailable from '../../../../../styles/images/NoAlertsAvailable.svg';
import EmwExportBtn from '../../../../components/EmwExportBtn/EmwExportBtn';
import { getNotifications } from '../../../header/redux/api';

const SORT = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const sort = (a, b) => {
  const firstDate = new Date(a.raiseDate).getTime();
  const secondDate = new Date(b.raiseDate).getTime();
  if (firstDate < secondDate) {
    return 1;
  } else if (firstDate > secondDate) {
    return -1;
  }
  return 0;
};

export default function HistoryModal() {
  const dispatch = useDispatch();
  const api = useApi();
  const historyModal = useSelector(alertHistoryModalSelector);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [order, setOrder] = useState(SORT.DESC);
  const [alerts, setAlerts] = useState([]);
  const [alertOptions, setAlertOptions] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [sortOrder, setSortOrder] = useState(SORT.DESC);
  const [hasNotifications, setHasNotification] = useState(true);
  const [chartName, setChartName] = useState('');
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const noAlertsClassname = ctx({
    flex: !hasNotifications,
  });

  useEffect(() => {
    if (!historyModal) {
      return;
    }
    (async () => {
      const response = await api.init(getNotifications, null, true);
      try {
        let name = '';
        let key = '';
        let notificationKey = '';

        if (historyModal.chart.name === CHARTS.MOOD) {
          name = 'Mood';
          key = 'moodAlerts';
          notificationKey = 'moodNotifications';
        }

        if (historyModal.chart.name === CHARTS.CONFIDENCE_TO_DELIVER) {
          name = 'Confidence to deliver';
          key = 'confidenceAlerts';
          notificationKey = 'confidenceNotifications';
        }

        if (historyModal.chart.name === CHARTS.DOTD) {
          name = 'DOTD (Development On Time Delivery)';
          key = 'dotdAlerts';
          notificationKey = 'dotdNotifications';
        }

        if (historyModal.chart.name === CHARTS.DAVD) {
          name = 'DAVD (Development Average Delay)';
          key = 'davdAlerts';
          notificationKey = 'davdNotifications';
        }

        if (historyModal.chart.name === CHARTS.KEY_PROFILE_FIT) {
          name = 'Key profile fit';
          key = 'keyProfileFitAlerts';
          notificationKey = 'keyProfileFitNotifications';
        }

        if (historyModal.chart.name === CHARTS.SKILL_COVERAGE) {
          name = 'Skill Coverage';
          key = 'skillCoverageAlerts';
          notificationKey = 'skillCoverageNotifications';
        }

        setChartName(name);
        if (!response.data.hasOwnProperty(key)) {
          // TODO: ask Bo team to add keyProfileFit and skillCoverage
          setAlerts([]);
          setNotifications([]);
          setAlertOptions([]);

          return;
        }

        const alerts = response.data[key];
        const notification = response.data[notificationKey].map(notification => {
          notification.alertType = alerts.find(alert => notification.alertId === alert.id);
          return notification;
        });

        setAlerts(notification.slice(0, 50) || []);
        setNotifications(notification || []);
        setAlertOptions(response.data[key]);

        if (!notification.length) {
          setHasNotification(false);
        }
      } catch (e) {}
    })();

    return () => {
      setSelectedOrg('');
      setAlerts([]);
      setNotifications([]);
    };
  }, [historyModal.isOpen]);

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      return;
    }
  };

  const handleSelectOrg = evt => {
    const { selectedOption } = evt.detail;
    const allNotification = cloneDeep(notifications);

    setAlerts(() => {
      return allNotification.filter(alert => {
        return alert.alertId === Number(selectedOption);
      });
    });
    setSelectedOrg(selectedOption);
  };

  const handleSetOrder = () => {
    const notificationList = cloneDeep(alerts);
    setOrder(currentOrder => {
      if (currentOrder === SORT.DESC) {
        setAlerts(
          notificationList.sort((first, second) => {
            return new Date(first.raiseDate) - new Date(second.raiseDate);
          })
        );
        return SORT.ASC;
      }

      setAlerts(
        notificationList.sort((first, second) => {
          return new Date(second.raiseDate) - new Date(first.raiseDate);
        })
      );
      return SORT.DESC;
    });
  };

  const handleDateFromChange = date => {
    const allNotifications = cloneDeep(notifications);
    setDateFrom(date);
    setAlerts(
      allNotifications.filter(notification => {
        return new Date(notification.raiseDate) >= new Date(date);
      })
    );
  };

  const handleDateToChange = date => {
    const allNotifications = cloneDeep(notifications);
    setDateTo(date);
    setAlerts(
      allNotifications.filter(notification => {
        return new Date(notification.raiseDate) <= new Date(date);
      })
    );
  };

  return (
    <StyledHistoryModal
      open={historyModal.isOpen}
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      disableEscapeKeyDown
    >
      <DialogTitle className="dialog-title">
        <Grid
          container
          justifyContent="space-between"
          className="items-center border-b-2 border-primary-500 pb-xs"
        >
          <EmwTypography classes="text-primary-500" fontSize={6} fontWeight="bold">
            Notifications - {chartName}
          </EmwTypography>
          <EmwExportBtn root="chart" action="exportAlertsNotifications" />
        </Grid>

        <Grid
          container
          className="filters mt-s items-center"
          flexWrap="nowrap"
          sx={{ overflow: 'hidden' }}
        >
          <Grid item sm={5} sx={{ height: '32px', overflow: 'hidden' }}>
            <Grid container>
              <Grid item sm={8}>
                <label className="flex type-selector items-center border-bluegrey-200 pr-xs">
                  <EmwTypography fontSize={9} classes="mr-xxs">
                    TYPE:
                  </EmwTypography>
                  <div>
                    {alertOptions.length > 0 ? (
                      <TdsDropdownSelect
                        placeholder="Select Type"
                        value={selectedOrg}
                        onSelectedOption={handleSelectOrg}
                      >
                        {alertOptions.map(alert => {
                          return <AlertOption key={alert.id} alert={alert} />;
                        })}
                      </TdsDropdownSelect>
                    ) : (
                      <TdsDropdownSelect placeholder="Select Type"></TdsDropdownSelect>
                    )}
                  </div>
                </label>
              </Grid>

              <Grid item sm={4} className="sort-filter px-xs flex items-center justify-between">
                <EmwTypography fontSize={9}>Sort</EmwTypography>
                {order === SORT.DESC && (
                  <EmwButton size="small" variant="ghost" onClick={handleSetOrder}>
                    <TdsIcon icon="arrow_downward" size="medium" />
                  </EmwButton>
                )}
                {order === SORT.ASC && (
                  <EmwButton size="small" variant="ghost" onClick={handleSetOrder}>
                    <TdsIcon icon="arrow_upward" size="medium" />
                  </EmwButton>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={7}>
            <Grid container flexDirection="initial" className="ml-xs" justifyContent="space-evenly">
              {/* <Grid item xs={6}> */}
              <label className="flex items-center pr-xs">
                <EmwTypography fontSize={9} classes="mr-xxs">
                  FROM:
                </EmwTypography>
                <EmwDatePicker
                  inputId="start-date"
                  handleChange={handleDateFromChange}
                  maxDate={dateTo}
                />
              </label>
              {/* </Grid> */}
              {/* <Grid item sm={6}> */}
              <label className="flex items-center pr-xs">
                <EmwTypography fontSize={9} classes="mr-xxs">
                  TO:
                </EmwTypography>
                <EmwDatePicker
                  inputId="end-date"
                  handleChange={handleDateToChange}
                  minDate={dateFrom}
                />
              </label>
              {/* </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent className={noAlertsClassname}>
        {!hasNotifications ? (
          <Grid container className="no-alerts-available">
            <div>
              <img src={noAlertsAvailable} alt="no alerts available" />
            </div>
            <EmwTypography fontWeight="bold" fontSize={6}>
              No alerts available
            </EmwTypography>
            <EmwTypography fontWeight={8} classes="text-bluegrey-500">
              Change filter settings or add new alerts.
            </EmwTypography>
          </Grid>
        ) : (
          <Grid container className="history-list pb-xs">
            {alerts.map((alert, index) => {
              return <HistoryItem key={index} item={alert} />;
            })}
          </Grid>
        )}
      </DialogContent>

      <Box sx={{ padding: '0 15px 10px 15px' }}>
        <Divider sx={{ height: '2px' }} />
      </Box>

      <DialogActions className="flex-col">
        <EmwButton
          className="mb-xs"
          variant="filled"
          color="primary"
          classes="mr-xs"
          size="small"
          onClick={() => dispatch(alertHistoryModal({ isOpen: false, chart: null }))}
        >
          Close
        </EmwButton>

        <EmwTypography fontSize={9} classes="mt-xs">
          Displaying a maximum of 50 notifications. Change filter settings to see more.
        </EmwTypography>
      </DialogActions>
    </StyledHistoryModal>
  );
}
